/* @import url("https://fonts.googleapis.com/css?  family=Lora:400,400i,700,700i"); */

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lobster+Two&display=swap');

@font-face {
  font-family: "Lora";
  src: url("../Fonts/Lora.ttf");
  }


/* .patterns {
    height: 100vh;
  }
   */
 
  
   
   .patterns {
    height: 100vh;
  }
 
 @keyframes textAnimate {
   0% {
     stroke-dasharray: 0 50%;
     stroke-dashoffset:  20%;
     fill:hsl(0, 1%, 30%)
 
   }
   
   100% {
     stroke-dasharray: 50% 0;
     stroke-dashoffstet: -20%;
     fill: hsl(0, 1%, 14%)
   }
   
 }
 
  button {
    position: relative;
    align-items: center;
    padding: 12px 35px;
    background: #FEC195;
    font-size: 17px;
    font-weight: 500;
    color: #181818;
    border: 3px solid #FEC195;
    border-radius: 8px;
    box-shadow: 0 0 0 #fec1958c;
    transition: all 0.8s ease-in-out;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.8s ease;
  }
  
  button:hover {
    background: transparent;
    color: #FEC195;
    box-shadow: 0 0 25px #fec1958c;
  }
  
  button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .fil0 {
    fill: #FFFDEF
  }
  button {
    position: relative;
    padding: 12px 35px;
    background: #efb660;;
    font-size: 17px;
    font-weight: 500;
    color: #181818;
    border: 3px solid #efb660;
    border-radius: 8px;
    box-shadow: 0 0 0 #fec1958c;
    transition: all 0.9s ease-in-out;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s ease;
  }
  
  button:hover {
    background: transparent;
    color: #FEC195;
    box-shadow: 0 0 25px #fec1958c;
  }
  
  button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .fil0 {
    fill: #FFFDEF
  }
  @media (max-width: 398px){
    .patterns{
      padding: 10px;
    }

    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 50px;
      font-weight: 700;
      stroke-width: 2;
      animation: textAnimate 8s infinite alternate;
      
    }
    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 14%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 14%;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 0;
      right: 0;
    }
  }
  @media(min-width: 399px ) and (max-width: 500px){
    .patterns{
      padding: 10px;
    }
    .register_button{
      left: 28%;
      font-family: EB Garamond;
    }
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 68px;
      font-weight: 600;
      stroke-width: 3;
      animation: textAnimate 5s infinite alternate;
      
    }
    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 14%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 14%;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-top: 0;
      margin-left: 0;
    }
  }
  @media(min-width: 501px ) and (max-width: 1300px){
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 90px;
      font-weight: 600;
      stroke-width: 3;
      animation: textAnimate 5s infinite alternate;
      
    }

    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 8%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 8%;
      padding-top: 9px;
      padding-bottom: 9px;
      margin-top: 0;
      margin-left: 0;
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.8;
      animation: textAnimate 5s infinite alternate;
      font-size: 40px;
    }
  }
  @media (max-width: 501px){
    .patterns{
      padding: 10px;
    }
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    .lc_logo{
      width: 23%;
      margin-top: 0;
      margin-left: 0;
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.3;
      animation: textAnimate 5s infinite alternate;
      font-size: 30px;
    }
  }
  @media (min-width: 1301px){
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    .Title-text{
     left: 300px;
    }
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 180px;
      font-weight: 600;
      stroke-width: 4;
      animation: textAnimate 5s infinite alternate;
      
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.6;
      animation: textAnimate 5s infinite alternate;
      font-size: 55px;
    }

    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 6%;
      top: 10px;
      margin-left: 0;
    }
    .lc_logo{
      width: 6%;
      padding-top: 17px;
      padding-bottom: 20px;
      /* top: 10px; */
      margin-left: 0;
    }
  }