@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lobster+Two&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lobster+Two&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.DetailsForm .add-button {
  margin-bottom: 14px;
  background-color: #082e65;
}

.DetailsForm .add-button:active {
  color: var(--bs-btn-active-color);
  background-color: #0d4799;
  border-color: #072857;
}

.DetailsForm .club-options-render {
  margin-bottom: 10px;
}
.DetailsForm .input-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

.DetailsForm .error-message {
  font-size: 12px;
  color: #e04654;
  padding-top: 2px;
  font-weight: 600;
}

.DetailsForm .focus-input100::before {
  background: #e04654;
}

.DetailsForm .container-contact100-form-btn button[disabled] {
  background-color: #486c9e;
}

.DetailsForm .submit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DetailsForm .loading {
  padding-right: 9px;
}

.DetailsForm .error-toast {
  color: red;
  font-weight: 500;
}

.DetailsForm .error-toast-main {
  width: 100%;
}

.DetailsForm .error-toast-header {
  width: 100%;
}

.DetailsForm .error-toast-header .error-toast-header-text {
  width: 100%;
  font-style: italic;
  color: #890b0b;
  font-size: 1rem;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.outside-wrapper{
  background: transparent;
}
.DetailsForm{
  border-radius: 30px;
  background: #efdeba;
  opacity: 0.9;
  -webkit-backdrop-filter: blur(80px);
          backdrop-filter: blur(80px);
}

@media(min-width: 550px) and (max-width:991px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media (max-width: 992px){
  .photo{
    left: 40%;
    top: 38px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media(min-width: 993px ) and (max-width: 1300px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .photo{
    left: 40%;
    top: 65px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media (min-width: 1301px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .photo{
    left: 40%;
    top: 65px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
  }
}
/* center{
    position: absolute;
    top: 150%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%,-50%);
} */
/* .DetailsForm{
    position: absolute;
    top: 150%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%,-50%);
} */
/* *{
    height: 100%;
} */
.container{
    margin-top: 15%;
}
@media(max-width: 550px){
    .container{
        margin-top: 55%;
    } 
}
.DetailsForm{
    padding-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
}

/* @import url("https://fonts.googleapis.com/css?  family=Lora:400,400i,700,700i"); */

@font-face {
  font-family: "Lora";
  src: url(/static/media/Lora.ea5cbfa3.ttf);
  }


/* .patterns {
    height: 100vh;
  }
   */
 
  
   
   .patterns {
    height: 100vh;
  }
 
 @-webkit-keyframes textAnimate {
   0% {
     stroke-dasharray: 0 50%;
     stroke-dashoffset:  20%;
     fill:hsl(0, 1%, 30%)
 
   }
   
   100% {
     stroke-dasharray: 50% 0;
     stroke-dashoffstet: -20%;
     fill: hsl(0, 1%, 14%)
   }
   
 }
 
 @keyframes textAnimate {
   0% {
     stroke-dasharray: 0 50%;
     stroke-dashoffset:  20%;
     fill:hsl(0, 1%, 30%)
 
   }
   
   100% {
     stroke-dasharray: 50% 0;
     stroke-dashoffstet: -20%;
     fill: hsl(0, 1%, 14%)
   }
   
 }
 
  button {
    position: relative;
    align-items: center;
    padding: 12px 35px;
    background: #FEC195;
    font-size: 17px;
    font-weight: 500;
    color: #181818;
    border: 3px solid #FEC195;
    border-radius: 8px;
    box-shadow: 0 0 0 #fec1958c;
    transition: all 0.8s ease-in-out;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 2s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1.8s ease;
  }
  
  button:hover {
    background: transparent;
    color: #FEC195;
    box-shadow: 0 0 25px #fec1958c;
  }
  
  button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .fil0 {
    fill: #FFFDEF
  }
  button {
    position: relative;
    padding: 12px 35px;
    background: #efb660;;
    font-size: 17px;
    font-weight: 500;
    color: #181818;
    border: 3px solid #efb660;
    border-radius: 8px;
    box-shadow: 0 0 0 #fec1958c;
    transition: all 0.9s ease-in-out;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 0 #fffdef);
            filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s ease;
  }
  
  button:hover {
    background: transparent;
    color: #FEC195;
    box-shadow: 0 0 25px #fec1958c;
  }
  
  button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    -webkit-filter: drop-shadow(0 0 10px #fffdef);
            filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }
  
  .fil0 {
    fill: #FFFDEF
  }
  @media (max-width: 398px){
    .patterns{
      padding: 10px;
    }

    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 50px;
      font-weight: 700;
      stroke-width: 2;
      -webkit-animation: textAnimate 8s infinite alternate;
              animation: textAnimate 8s infinite alternate;
      
    }
    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 14%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 14%;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 0;
      right: 0;
    }
  }
  @media(min-width: 399px ) and (max-width: 500px){
    .patterns{
      padding: 10px;
    }
    .register_button{
      left: 28%;
      font-family: EB Garamond;
    }
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 68px;
      font-weight: 600;
      stroke-width: 3;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      
    }
    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 14%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 14%;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-top: 0;
      margin-left: 0;
    }
  }
  @media(min-width: 501px ) and (max-width: 1300px){
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 90px;
      font-weight: 600;
      stroke-width: 3;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      
    }

    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 8%;
      margin-top: 0;
      margin-left: 0;
    }
    .lc_logo{
      width: 8%;
      padding-top: 9px;
      padding-bottom: 9px;
      margin-top: 0;
      margin-left: 0;
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.8;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      font-size: 40px;
    }
  }
  @media (max-width: 501px){
    .patterns{
      padding: 10px;
    }
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    .lc_logo{
      width: 23%;
      margin-top: 0;
      margin-left: 0;
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.3;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      font-size: 30px;
    }
  }
  @media (min-width: 1301px){
    .register_button{
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-family: EB Garamond;
    }
    .Title-text{
     left: 300px;
    }
    svg text {
      font-family: Lora;
      letter-spacing: 15 px;
      stroke: #efb660;
      font-size: 180px;
      font-weight: 600;
      stroke-width: 4;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      
    }
    .Date{
      font-weight: 400;
      stroke-width: 1.6;
      -webkit-animation: textAnimate 5s infinite alternate;
              animation: textAnimate 5s infinite alternate;
      font-size: 55px;
    }

    .logo-div{
      display: flex;
      justify-content: space-between;
    }
    .verve_logo{
      width: 6%;
      top: 10px;
      margin-left: 0;
    }
    .lc_logo{
      width: 6%;
      padding-top: 17px;
      padding-bottom: 20px;
      /* top: 10px; */
      margin-left: 0;
    }
  }
