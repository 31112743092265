@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lobster+Two&display=swap');


.DetailsForm .add-button {
  margin-bottom: 14px;
  background-color: #082e65;
}

.DetailsForm .add-button:active {
  color: var(--bs-btn-active-color);
  background-color: #0d4799;
  border-color: #072857;
}

.DetailsForm .club-options-render {
  margin-bottom: 10px;
}
.DetailsForm .input-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

.DetailsForm .error-message {
  font-size: 12px;
  color: #e04654;
  padding-top: 2px;
  font-weight: 600;
}

.DetailsForm .focus-input100::before {
  background: #e04654;
}

.DetailsForm .container-contact100-form-btn button[disabled] {
  background-color: #486c9e;
}

.DetailsForm .submit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DetailsForm .loading {
  padding-right: 9px;
}

.DetailsForm .error-toast {
  color: red;
  font-weight: 500;
}

.DetailsForm .error-toast-main {
  width: 100%;
}

.DetailsForm .error-toast-header {
  width: 100%;
}

.DetailsForm .error-toast-header .error-toast-header-text {
  width: 100%;
  font-style: italic;
  color: #890b0b;
  font-size: 1rem;
  text-decoration-line: underline;
}

.outside-wrapper{
  background: transparent;
}
.DetailsForm{
  border-radius: 30px;
  background: #efdeba;
  opacity: 0.9;
  backdrop-filter: blur(80px);
}

@media(min-width: 550px) and (max-width:991px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media (max-width: 992px){
  .photo{
    left: 40%;
    top: 38px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media(min-width: 993px ) and (max-width: 1300px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .photo{
    left: 40%;
    top: 65px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
    font-family: EB Garamond;
  }
}
@media (min-width: 1301px){
  .DetailsForm{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .photo{
    left: 40%;
    top: 65px;
  }
  .images_payment{
    display: flex;
    flex-direction: row;
  }
  .upi1{
    font-size: 20px;
    text-align: center;
    font-family: EB Garamond;
  }
  .upi2{
    font-size: 15px;
    text-align: center;
    font-family: EB Garamond;
  }
  .Title-text{
    width: 100%;
  }
  .Member{
    text-align: center;
  }
}